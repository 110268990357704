import request from '@/utils/request'

export default {

  clients(params) {
    return request({
      method: 'get',
      url: 'delivery-order',
      params
    })
  },
  goods(params) {
    return request({
      method: 'get',
      url: 'delivery-order/goods',
      params
    })
  },
  begin() {
    return request({
      method: 'post',
      url: 'delivery-order/begin'
    })
  },
  payInfo(params) {
    return request({
      method: 'get',
      url: 'delivery-order/pay-info',
      params
    })
  },
  order(params) {
    return request({
      method: 'get',
      url: 'delivery-order/order',
      params
    })
  },
  unpaid(data) {
    return request({
      method: 'post',
      url: 'delivery-order/unpaid',
      data
    })
  },
  payment(data) {
    return request({
      method: 'post',
      url: 'delivery-order/payment',
      data
    })
  },
  recvInfo() {
    return request({
      method: 'get',
      url: 'delivery-order/recv-info'
    })
  },
  cancelPayment(data) {
    return request({
      method: 'post',
      url: 'delivery-order/revoke-payment',
      data
    })
  },
  getCurrentLocation(params) {
    return request({
      method: 'get',
      url: 'location',
      params
    })
  },
  changeCustomerLocation(data) {
    return request({
      method: 'post',
      url: 'apply-location',
      data
    })
  },
  ifNeedApplyLocation(params) {
    return request({
      method: 'get',
      url: 'apply-location/ifneed',
      params
    })
  }
}

export function setUnpaidReturn(data) {
  return request({
    method: 'post',
    url: 'delivery-order/unpaid-return',
    data
  })
}

export function toOrderNext(data) {
  return request({
    method: 'post',
    url: 'delivery-order/next',
    data
  })
}

export function getAodList() {
  return request({
    method: 'get',
    url: 'delivery-order/aod-list'
  })
}

export function getUnpaidReceives(params) {
  return request({
    method: 'get',
    url: 'delivery-order/unpaid-receive',
    params
  })
}

export function getUnpaidReceiveOrders(params) {
  return request({
    method: 'get',
    url: 'delivery-order/unpaid-receive/order',
    params
  })
}
