var render = function () {
  var _vm$currentUnpaidRece, _vm$currentUnpaidRece2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "签单收款",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showTips ? _c('div', {
    staticClass: "tips"
  }, [_vm._v("提示：点击查看签单明细，左划收款")]) : _vm._e(), _vm._l(_vm.list, function (unpaidReceive, index) {
    return _c('div', {
      key: index
    }, [_c('van-swipe-cell', {
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            attrs: {
              "type": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.handleShowPaymentQrcode(unpaidReceive);
              }
            }
          }, [_vm._v("收款")])];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      attrs: {
        "value-class": "amount",
        "title": unpaidReceive.client.name,
        "value": "\uFFE5".concat(unpaidReceive.amount),
        "is-link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleShowUnpaidReceiveOrders(unpaidReceive);
        }
      }
    })], 1), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [unpaidReceive.showOrders ? _c('van-cell-group', {
      staticClass: "orders",
      attrs: {
        "border": false
      }
    }, [unpaidReceive.loading ? _c('van-loading', {
      staticStyle: {
        "text-align": "center"
      }
    }) : _vm._e(), _vm._l(unpaidReceive.orders, function (order, index2) {
      return _c('van-cell', {
        key: index2,
        attrs: {
          "title": order.num,
          "value-class": "amount",
          "value": "\uFFE5".concat(order.unpaid_amount)
        },
        scopedSlots: _vm._u([{
          key: "label",
          fn: function fn() {
            return [order.unpaid_return_amount < 0 ? _c('span', [_vm._v(_vm._s("\u7B7E\u5355\u9000\u8D27\uFF1A\uFFE5".concat(order.unpaid_return_amount)))]) : _vm._e()];
          },
          proxy: true
        }], null, true)
      });
    })], 2) : _vm._e()], 1)], 1);
  })], 2)], 1), _c('my-dialog', {
    attrs: {
      "title": "签单收款码",
      "show-cancel-button": false
    },
    model: {
      value: _vm.showPaymentQrcodeDialog,
      callback: function callback($$v) {
        _vm.showPaymentQrcodeDialog = $$v;
      },
      expression: "showPaymentQrcodeDialog"
    }
  }, [_c('div', {
    staticClass: "payment-qrcode"
  }, [_c('div', {
    staticClass: "qrcode-label"
  }, [_vm._v("客户：" + _vm._s((_vm$currentUnpaidRece = _vm.currentUnpaidReceive) === null || _vm$currentUnpaidRece === void 0 ? void 0 : _vm$currentUnpaidRece.client.name))]), _c('div', {
    staticClass: "qrcode-label"
  }, [_vm._v("金额："), _c('span', {
    staticClass: "amount"
  }, [_vm._v("￥" + _vm._s((_vm$currentUnpaidRece2 = _vm.currentUnpaidReceive) === null || _vm$currentUnpaidRece2 === void 0 ? void 0 : _vm$currentUnpaidRece2.amount))])]), _c('van-image', {
    attrs: {
      "src": _vm.qrcodeData
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }