<template>
  <div>
    <my-nav-bar
      title="签单收款"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list v-model="loading" :finished="finished" @load="getList">
        <div v-if="showTips" class="tips">提示：点击查看签单明细，左划收款</div>
        <div v-for="(unpaidReceive, index) in list" :key="index">
          <van-swipe-cell>
            <van-cell
              value-class="amount"
              :title="unpaidReceive.client.name"
              :value="`￥${unpaidReceive.amount}`"
              is-link
              @click="handleShowUnpaidReceiveOrders(unpaidReceive)"
            />
            <template #right>
              <van-button type="primary" @click="handleShowPaymentQrcode(unpaidReceive)">收款</van-button>
            </template>
          </van-swipe-cell>
          <transition name="fade">
            <van-cell-group v-if="unpaidReceive.showOrders" :border="false" class="orders">
              <van-loading v-if="unpaidReceive.loading" style="text-align: center;" />
              <van-cell
                v-for="(order, index2) in unpaidReceive.orders"
                :key="index2"
                :title="order.num"
                value-class="amount"
                :value="`￥${order.unpaid_amount}`"
              >
                <template #label>
                  <span v-if="order.unpaid_return_amount < 0">{{ `签单退货：￥${order.unpaid_return_amount}` }}</span>
                </template>
              </van-cell>
            </van-cell-group>
          </transition>
        </div>
      </van-list>
    </div>
    <my-dialog
      v-model="showPaymentQrcodeDialog"
      title="签单收款码"
      :show-cancel-button="false"
    >
      <div class="payment-qrcode">
        <div class="qrcode-label">客户：{{ currentUnpaidReceive?.client.name }}</div>
        <div class="qrcode-label">金额：<span class="amount">￥{{ currentUnpaidReceive?.amount }}</span></div>
        <van-image :src="qrcodeData" />
      </div>
    </my-dialog>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getUnpaidReceives, getUnpaidReceiveOrders } from '@/api/delivery_order'
import qrcode from 'qrcode'
import MyDialog from '@/components/my-dialog'
export default {
  components: { myNavBar, MyDialog },
  data() {
    return {
      list: [],
      listQuery: {
        page: 0
      },
      loading: false,
      finished: false,
      showEmpty: false,
      showTips: false,
      showPaymentQrcodeDialog: false,
      qrcodeData: null,
      currentUnpaidReceive: null
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getUnpaidReceives(this.listQuery).then(res => {
        res.data.list.forEach(ur => {
          ur.showOrders = false
          ur.orders = []
        })
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < 10
        this.showEmpty = this.list.length === 0
        this.showTips = !this.showEmpty
      }).catch(() => {
        this.loading = false
        this.finished = true
        this.showEmpty = true
      })
    },
    handleShowUnpaidReceiveOrders(unpaidReceive) {
      unpaidReceive.showOrders = !unpaidReceive.showOrders
      if (unpaidReceive.orders.length !== 0) {
        return
      }

      unpaidReceive.loading = true
      const params = {
        unpaid_receive_id: unpaidReceive.id
      }
      getUnpaidReceiveOrders(params).then(res => {
        unpaidReceive.orders = res.data
        unpaidReceive.loading = false
      }).catch(() => {
        unpaidReceive.loading = false
      })
    },
    handleShowPaymentQrcode(unpaidReceive) {
      this.currentUnpaidReceive = unpaidReceive
      const url = process.env.VUE_APP_PAYMENT_URL + 'unpaid-receive/?key=' + unpaidReceive.key
      qrcode.toDataURL(url, { width: 200 }, (err, data) => {
        if (err) {
          this.fail('生成付款二维码失败')
          console.log(err)
          return
        }
        this.showPaymentQrcodeDialog = true
        this.qrcodeData = data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/color.scss";
  .tips {
    padding: 10px 15px;
    color: #989898;
  }
  .amount {
    color: $amountRedColor;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .qrcode-label {
    padding-top: 10px;
    font-size: 14px;
    color: #323232;
  }
  .payment-qrcode {
    text-align: center;
  }
</style>
